import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
/** header component*/
export class HeaderComponent implements OnInit {
  /** header ctor */
  imgUrl: any;
  logo: any;
  departmentName: any;
  govtName: any;
    loginUrl: any;
  constructor(private appComponent: AppComponent, private http: HttpClient) {
    this.imgUrl = window["orginalImgUrl"];
    this.loginUrl = window["HeadlessUI"];
  }
  ngOnInit() {
    var screenwidth = $(window).width();
    if (screenwidth > 1024) {
      this.getLogoImage();
      this.getDepartmentName();
      this.getGovtName();
    }
  }

  getLogoImage() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByCategory/Logo', {
    }).subscribe((data: any) => {
      this.logo = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }

  getDepartmentName() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByCategory/Department Name', {
    }).subscribe((data: any) => {
      this.departmentName = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
      //console.log(JSON.stringify(this.departmentName))
    });
  }
  getGovtName() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByCategory/Government Of', {
    }).subscribe((data: any) => {
      this.govtName = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
      //console.log(JSON.stringify(this.govtName))
    });
  }
}
