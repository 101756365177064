
<section class="container-fluid bg pt-2 pb-4">
  <div class="col-lg-12">
    <div class="row pr-3">
      <div class="col-lg-10" *ngIf="carouselSpinner" style="height: 27.7rem;">
        <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots" [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
      </div>
      <div class="col-lg-10" *ngIf="!carouselSpinner">
        <div id="carousel" class="carousel slide" data-ride="carousel">
          <!-- The slideshow -->
          <div class="carousel-inner pt-2 pb-2">
            <div class="carousel-item {{i==0?'active':''}}" *ngFor="let Carousel of carouselList;let i=index">
              <img class="img-responsive" *ngIf="Carousel.images[0]" [src]="imgUrl+Carousel.images[0].filepath" alt="slide+{{i}}">
              <div class="card-text textBanner p-2 w-100">
                <p class="mb-0">{{Carousel.title}}</p>
              </div>
            </div>
          </div>
          <!-- Left and right controls -->
          <a class="carousel-control-prev" href="#carousel" data-slide="prev" style="width: 4rem;">
            <span class="carousel-control-prev-icon"></span>Previous
          </a>
          <a class="carousel-control-next" href="#carousel" data-slide="next" style="width: 4rem;">
            Next<span class="carousel-control-next-icon"></span>
          </a>
        </div>
      </div>
      <div class="col-lg-2 d-none d-lg-block p-0">
        <div class="card card-flip mt-2" style="height:446px;background: #c3c3c3 !important;" *ngIf="cardSpinner">
          <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots" [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
        </div>
        <div class="card card-flip mt-2" *ngFor="let message of cardList|orderBy :'listingorder';let i=index">
          <div class="card-front text-white bg-dark">
            <div class="card-body p-2">
              <div class="card-text text p-2 w-100">
                <p class="mb-0">{{message.title}}</p>
                <p class="mb-0">( {{message.shortDescription}} )</p>
              </div>
              <img class="img" *ngIf="message.images[0]" [src]="imgUrl+message.images[0].filepath">
            </div>
          </div>
          <div class="card-back">
            <div class="card-body">
              <p class="card-text">Message from the {{message.shortDescription}}</p>
              <button class="btn" data-toggle="modal" data-target="#Message" (click)="shortDescription=message.shortDescription;;image1=message.images[0].filepath;content=message.content">Message</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-lg-none mt-3">
        <div class="row">
          <div class="col-6 pl-1 pr-1" *ngFor="let message of cardList|orderBy :'listingorder';let i=index">
            <div class="card-body p-2">
              <div class="card-text sm-text p-2">
                <p class="mb-0">{{message.title}}</p>
                <p class="mb-0">( {{message.shortDescription}} )</p>
              </div>
              <img class="sm-img" *ngIf="message.images[0]" [src]="imgUrl+message.images[0].filepath">
            </div>
            <div class="text-center mt-1 mb-2">
              <button data-toggle="modal" data-target="#Message" (click)="shortDescription=message.shortDescription;image1=message.images[0].filepath;content=message.content" class="sm-more-btn w-100">Message</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Message 1 Modal-->
<div class="modal fade" id="Message">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h6 class="modal-title">Message from the {{shortDescription}}</h6>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body mb-5">
        <p style="float:left;padding-right:30px;margin-bottom:0px;">
          <img class="img-thumbnail" *ngIf="image1" [src]="imgUrl+image1" alt="">
        </p>
        <p *ngIf="!content">Contents Awaited !!!</p>
        <p class="section-excerpt " style="text-align:justify;padding-bottom: 30px;float: initial;" [innerHtml]="content|sanitizeHtml">
        </p>
      </div>
    </div>
  </div>
</div>

<!-- page-content" -->
<section id="about" class="container-fluid pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 mb-2 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-right">
        <div class="homecontent w-100 height" *ngIf="circularSpinner" style="background: #c3c3c3 !important;">
          <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots" [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
        </div>
        <div class="homecontent w-100 height" *ngIf="!circularSpinner">
          <h3>Circular and Notification</h3>
          <ul class="content-box1 imt pl-0 h-75 players">
            <li *ngIf="!circularandnotification">
              Sorry! No record found...
            </li>
            <li class="corner-cut corner-danger pb-2 mb-3" *ngFor="let circular of circularandnotification|orderBy :'listingorder';let i=index">
              <span class="new-cus" *ngIf="currentDate==circular.createdOn">New</span>
              <div class="col-md-12 news-head pl-0 pr-0" style="font-family:Cambria">
                <h5 class="title" *ngIf="circular.title">{{circular.title}}</h5>
                <i *ngIf="circular.shortDescription">Reference No.:</i>({{circular.shortDescription}})
                <h6 *ngIf="circular.author">{{circular.author}}</h6>
                <i class="fa fa-calendar mr-2" *ngIf="circular.createdOn" aria-hidden="true"></i> <i>{{circular.createdOn | date}}</i>
              </div>
            </li>
          </ul>
          <div class="text-center mt-3">
            <a routerLink="/circularandnotification" class="more-btn">View All</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-2 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-right">
        <div class="homecontent w-100 height" *ngIf="noticeSpinner" style="background: #c3c3c3 !important;">
          <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots" [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
</div>
        <div class="homecontent w-100 height" *ngIf="!noticeSpinner">
          <h3>Notice</h3>
          <ul class="content-box1 imt pl-0 h-75">
            <li *ngIf="!notice">
              Sorry! No record found...
            </li>
            <li class="corner-cut corner-danger pb-2 mb-3  border-bottom" *ngFor="let note of notice|orderBy :'listingorder';let i=index">
              <span class="new-cus" *ngIf="currentDate==note.createdOn">New</span>
              <div class="col-md-12 news-head pl-0 pr-0 " style="font-family:Cambria">
                <h5 class="title" *ngIf="note.title">{{note.title}}</h5>
                <h6 *ngIf="note.author">{{note.author}}</h6>
                <i class="fa fa-calendar mr-2" *ngIf="note.createdOn" aria-hidden="true"></i> <i>{{note.createdOn | date}}</i>
              </div>
            </li>
          </ul>
          <div class="text-center mt-3">
            <a routerLink="/notice-board" class="more-btn">View All</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-2 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-right">
        <div class="homecontent w-100 height" *ngIf="sifSpinner" style="background: #c3c3c3 !important;">
          <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots"
                       [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
</div>
        <div class="homecontent w-100 height" *ngIf="!sifSpinner">
          <h3>SIF</h3>
          <ul class="content-box1 pl-0 h-75">
            <li *ngIf="!sif">
              Sorry! No record found...
            </li>
            <li *ngFor="let sifdata of sif|orderBy :'listingorder'" class="p-2">
              <a *ngIf="sifdata.images.length>0" href="{{fileUrl}}{{sifdata.images[0].filepath}}">
                {{sifdata.title}}
              </a>
            </li>
          </ul>
          <div class="text-center mt-3">
            <a routerLink="/sif" class="more-btn">View All</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="ongoingproject" class="pt-4 pb-4">
  <div class="container">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-8">
          <h2>Ongoing Projects</h2>
        </div>
       
      </div>
    </div>
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-3">
          <label>Department</label>
          <select class="form-control form-control-sm" [(ngModel)]="filterdata.dept" style="text-transform: uppercase;">
            <option value=null>--Select Department--</option>
            <option value="allDepartment">All Department</option>
            <option *ngFor="let data of filterData" value="{{data.departmentId}}" style="width: 10% !important; text-transform: uppercase;">
            {{data.departmentName}}</option>
          </select>
        </div>
        <div class="col-lg-5 pt-4 pl-0 pr-0">
          <button class="btn btn-success mr-xl-2 pl-2 pr-2" (click)="FilterOnGoingProjects(filterdata)"><i class="fa fa-filter mr-1" aria-hidden="true"></i>Filter</button>
          <button class="btn btn-info ml-2 mr-xl-2 pl-2 pr-2" (click)="ClearOnGoingProjectFilter()"><i class="fa fa-refresh mr-1" aria-hidden="true"></i>Clear</button>
        </div>
        <div class="col-lg-4">
          <label></label>
          <input type="text" class="form-control form-control-sm" [(ngModel)]="Search" (click)="search()" placeholder="Search">

        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive container mt-3">
    <table class="table table-hover table-bordered border" #TABLE>
      <thead>
        <tr>
          <th class=" text-center" (click)="sort('projectname')">
            Project Name
            <span *ngIf="column !='projectname'"><i class="fa fa-sort-desc"></i></span>
            <span *ngIf="column =='projectname' && isDesc"><i class="fa fa-sort-asc"></i></span>
            <span *ngIf="column =='projectname' && !isDesc"><i class="fa fa-sort-desc"></i></span>
          </th>
          <th class=" text-center" (click)="sort('fundingagencyname')">
            Scheme Name
            <span *ngIf="column !='fundingagencyname'"><i class="fa fa-sort-desc"></i></span>
            <span *ngIf="column =='fundingagencyname' && isDesc"><i class="fa fa-sort-asc"></i></span>
            <span *ngIf="column =='fundingagencyname' && !isDesc"><i class="fa fa-sort-desc"></i></span>
          </th>
          <th class=" text-center" (click)="sort('sanctionedamount')">
            Sanctioned Amount
            <span *ngIf="column !='sanctionedamount'"><i class="fa fa-sort-desc"></i></span>
            <span *ngIf="column =='sanctionedamount' && isDesc"><i class="fa fa-sort-asc"></i></span>
            <span *ngIf="column =='sanctionedamount' && !isDesc"><i class="fa fa-sort-desc"></i></span>
          </th>
          <th class=" text-center" (click)="sort('sanctionedyear')">
            Sanctioned Year
            <span *ngIf="column !='sanctionedyear'"><i class="fa fa-sort-desc"></i></span>
            <span *ngIf="column =='sanctionedyear' && isDesc"><i class="fa fa-sort-asc"></i></span>
            <span *ngIf="column =='sanctionedyear' && !isDesc"><i class="fa fa-sort-desc"></i></span>
          </th>
          <th class=" text-center" (click)="sort('physicalprogress')">
            Physical Progress(%)
            	

            <span *ngIf="column !='physicalprogress'"><i class="fa fa-sort-desc"></i></span>
            <span *ngIf="column =='physicalprogress' && isDesc"><i class="fa fa-sort-asc"></i></span>
            <span *ngIf="column =='physicalprogress' && !isDesc"><i class="fa fa-sort-desc"></i></span>
          </th>
          <th class=" text-center" (click)="sort('financialprogress')">
            Financial Progress(%)
            <span *ngIf="column !='financialprogress'"><i class="fa fa-sort-desc"></i></span>
            <span *ngIf="column =='financialprogress' && isDesc"><i class="fa fa-sort-asc"></i></span>
            <span *ngIf="column =='financialprogress' && !isDesc"><i class="fa fa-sort-desc"></i></span>
          </th>
          
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" *ngIf="(onGoingProjects | filter:Search).length==0">
          <td colspan="9">Sorry! No record found...</td>
        </tr>
        <tr *ngFor="let data of onGoingProjects | filter:Search | paginate: { itemsPerPage: itemsPerPage, currentPage: paginationPageNumber };index as i">
          <td style="text-align: center;">{{data.projectname}}</td>
          <td style="text-align: center;">{{data.fundingagencyname}}</td>
          <td style="text-align: center;">{{data.sanctionedamount}}</td>
          <td style="text-align: center;">{{data.sanctionedyear| date : 'dd-MMM-yyyy'}}</td>
          <td style="text-align: center;">{{data.physicalprogress }}</td>
          <td style="text-align: center;">{{data.financialprogress }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="container pt-2">
    <div class="col-lg-12 text-center" >
      <div class="row">
        <div class="col-lg-4 pl-0">

          <p style="float:left">
            Rows per page:  <select style="width: 44px;" (change)="itemPerPagePagination($event)" id="pageItem">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </p>
         
        </div>
        <div class="col-lg-8 pr-0" style="text-align:right">
          <pagination-controls (pageChange)="paginationPageNumber = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="photos">
  <div class="container">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-6 mb-4">
          <h2>Photos</h2>
          <div class="w-100 video-list-box">
            <div id="photoCarousal" *ngIf="albumSpinner" class="carousel slide" data-ride="carousel" style="min-height: 22.5rem; background: #c3c3c3 !important;">
              <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots"
                           [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
            </div>
            <div id="photoCarousal" class="carousel slide" data-ride="carousel" style="min-height: 22.5rem;" *ngIf="!albumSpinner">
              <!-- The slideshow -->
              <div class="carousel-inner">
                <div *ngIf="!albumList" style="min-height: 22.5rem;display: flex;align-items: center;justify-content: center;">
                  Sorry! No album images found...
                </div>
                <div class="carousel-item text-center p-3 {{i==0?'active':''}}" *ngFor="let albumListCarousel of albumList|orderBy :'listingorder';let i=index">
                  <div class="gallery_product corner-cut corner-danger">
                    <img class="img-fluid" alt="slide+{{i}}" *ngIf="albumListCarousel.images.length>0" [src]="imgUrl+albumListCarousel.images[0].filepath" />
                    <span class="new-cus" *ngIf="currentDate==albumListCarousel.postdate">New</span>
                    <div class="text-size p-2 w-100">
                      <p class="mb-0 label">
                        {{albumListCarousel.albumname}}
                      </p>
                      <p class="mb-0 label">
                        {{albumListCarousel.title}}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Left and right controls -->
                <a class="carousel-control-prev" href="#photoCarousal" data-slide="prev" style="width: 4rem;">
                  <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#photoCarousal" data-slide="next" style="width: 4rem;">
                  <span class="carousel-control-next-icon"></span>
                </a>
              </div>


            </div>
            <div class="ml-4 pb-3" *ngIf="!albumSpinner">
              <a routerLink="/photo-gallery" class="more-btn">View All</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <h2>Videos</h2>
          <div class="w-100 video-list-box">
            <div id="photoCarousal" *ngIf="videoSpinner" style="min-height: 22.5rem; background: #c3c3c3 !important;">
              <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots"
                           [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
            </div>
            <div class="p-3 mb-0" *ngIf="!videoSpinner">
              <ul class="videos-list pr-2 pl-0 pt-0 pb-0 mb-0" *ngIf="!videos" style="min-height: 20.5rem;display: flex;align-items: center;justify-content: center;">
                <li >
                  Sorry! No album videos found...
                </li>
                </ul>
                <ul class="videos-list pr-2 pl-0 pt-0 pb-0 mb-0" *ngIf="videos">
                  <li *ngFor="let videoalbumimg of videos|orderBy :'listingorder'">
                    <div class="box col-lg-12 mt-2 mb-2">
                      <div class="row">
                        <div class="col-lg-4 p-0 corner-cut corner-danger">
                          <span class="new-cus" *ngIf="currentDate==videoalbumimg.postdate">New</span>
                          <img class="img-fluid video-img h-100" *ngIf="videoalbumimg" src="{{videoalbumimg.linkimg.includes('http')?videoalbumimg.linkimg:imgUrl + videoalbumimg}}">
                        </div>
                        <div class="col-lg-8 p-2 text-dark">
                          <h6 class="video-description font-weight-bold">
                            {{videoalbumimg.title}}
                          </h6>
                          <i class="w-100" style="font-size: 13px;">{{videoalbumimg.authorname}}</i>
                        </div>
                      </div>
                      <div class="box-content">
                        <h3 class="title">  {{videoalbumimg.albumtitle}}</h3>
                      </div>
                      <ul class="icon">
                        <li>
                          <a href="{{videoalbumimg.link}}" target="_blank"><i class="fa fa-play-circle"></i></a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
            </div>
            <div class="ml-4 pb-3" *ngIf="!videoSpinner">
              <a routerLink='/video-gallery' class="more-btn">View All</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-contactus></app-contactus>

