<div class="sticky-top">
  <div class="pl-0 pr-0">
    <nav class="navbar navbar-expand-lg w-100 p-0 pl-xl-5 ">
      <div class="d-lg-none col-12">
        <div class="d-lg-none pt-2 pb-2 container-fluid ml-md-3">
          <div class="col-12">
            <div class="row">
              <div class="col-1 p-0 ml-n4" id="logo" *ngFor="let data of logo|orderBy:'listingorder'; let i= index">
                <img class="log" *ngIf="data.images[0]" [src]="imgUrl+data.images[0]">
              </div>
              <div class="col-10 pr-0 pl-4">
                <div *ngFor="let data of departmentName|orderBy:'listingorder'; let i= index">
                  <p class="department mb-0 font-weight-bold" *ngIf="data">{{data.title}}</p>
                </div>
                <div *ngFor="let data of govtName|orderBy:'listingorder'; let i= index">
                  <p class="govt mb-0 font-weight-bold" *ngIf="data">{{data.title}}</p>
                </div>

              </div>
              <div class="col-1 pl-lg-5">
                <button class="navbar-toggler collapsed ml-lg-5" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-expanded="false">
                  <span class="navbar-toggler-icon">
                    <i class="fa fa-bars" aria-hidden="true" style="color:#e6e6ff;font-size: 5vw;"></i>
                  </span>
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="collapse navbar-collapse nav-menu pl-4 pr-4 " id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a [routerLink]='["/"]'><i class="fa fa-home" aria-hidden="true" style="font-size: 20px;"></i></a>
          </li>
          <li class="nav-item" *ngFor="let menu of mainmenu">
            <div *ngIf="menu.submenu == false">
              <a href="{{menu.link}}" *ngIf="menu.routinglink=='external'" target="_blank">{{menu.title}}</a>
              <a [routerLinkActive]='["link-active"]' [routerLink]='"/"+menu.link' *ngIf="menu.routinglink=='internal'">{{menu.title}}</a>
            </div>
            <div class="nav-item dropdown" *ngIf="menu.submenu == true">
              <a class="nav-link dropdown-toggle " href="#" id="navbardrop" data-toggle="dropdown">
                {{menu.title}}
              </a>
              <div class="dropdown-menu">
                <span *ngFor="let submenu of menu.submenuList">
                  <a class="dropdown-item" *ngIf="submenu.routinglink=='internal'" [routerLinkActive]='["link-active"]' [routerLink]='"/"+submenu.link'>{{submenu.title}}</a>
                  <a class="dropdown-item" *ngIf="submenu.routinglink=='external'" target="_blank" href="{{submenu.link}}">{{submenu.title}}</a>
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container" style="position: absolute;">
      <a class="login btn btn-sm btn-dark ml-3 pl-2 pr-2 float-right d-lg-none" href="{{loginUrl}}" target="_blank">
        <h6>Login</h6>
      </a>
    </div>
  </div>

</div>
