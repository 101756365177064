import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss']
})

export class VideoGalleryComponent implements OnInit {
  imgUrl: any;
  MenuList: any;
  title: any;
  route: any = '';
  videoalbumimg: any = [];
  videoalbumList: any;
  videoalbumimages: any = [];
  videoalbumtitle: any = '';
  videoalbum: boolean = false;
  videoalbumimage: boolean = false;
  data: any;
  currentDate: any = new Date();
  video: any;
  videoSpinner: boolean;
  videoImageSpinner: boolean;
    withoutAlbumImages: any[];
  
  constructor(private appComponent: AppComponent, private http: HttpClient, private router: Router, private datePipe: DatePipe) {

    var str = this.router.url;
    this.route = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd' + 'T00:00:00');

  }
  ngOnInit() {
    this.imgUrl = window["orginalImgUrl"];
    this.getMenu();
    this.getvideoalbum();
  }

  getMenu() {
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/', {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.link == this.route);
      this.title = this.MenuList[0].title;


    }
      , (err) => {
      });
  }

  albumListGroup: any = [];
  getvideoalbum() {
    this.http.get(this.appComponent.APIUrl + 'AlbumMaster/0', {
    }).subscribe((data: any) => {
      this.albumListGroup = data.albumDTO.filter(x => x.status == 'Active');
      this.getVideoGallery();
    }
      , (err) => {
      });
  }

  getVideoGallery() {
    let $this = this;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/' + this.route, {
    }).subscribe((data: any) => {
      this.videoalbumList = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
      this.withoutAlbumImages = [];
      data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1 && x.albumid == 0).forEach(function (list) {
        let videos = list.video.split(',');
        videos.forEach(function (video) {
          let id;
          if (video.match(/watch\?v=([a-zA-Z0-9\-_]+)/)) {
            id = video.substring(video.indexOf("=") + 1);
            $this.createYoutubeVideo(id,"withoutAlbum");
          }
          else {
            id = video.substring(video.indexOf("d/") + 1).replace('/', '');
            $this.createYoutubeVideo(id, "withoutAlbum");
          }
        })
      })
      this.albumListGroup.forEach(function (albumGroup, index) {
        $this.albumListGroup[index].videos = [];
        $this.videoalbumList.forEach(function (album) {
          if (albumGroup.id == album.albumid) {
            let videos = album.video.split(',');
            videos.forEach(function (video) {
              $this.albumListGroup[index].videos.push(video);
              $this.albumListGroup[index].createdOn = album.updatedOn != '0001-01-01T00:00:00' ? album.updatedOn : album.createdOn;
              $this.albumListGroup[index].listing = $this.datePipe.transform($this.albumListGroup[index].createdOn, "yyyy")
                + $this.datePipe.transform($this.albumListGroup[index].createdOn, "mm")
                + $this.datePipe.transform($this.albumListGroup[index].createdOn, "dd")
            })
          }
        })

      })
      let album = this.albumListGroup.filter(x => x.videos.length > 0);
      this.videoalbumList = album.reverse();
     
    }
      , (err) => {
      });
  }

  getvideoalbumImages(videoalbum: any) {
    console.log(videoalbum)
    this.videoalbumtitle = videoalbum.albumname;
    this.videoalbumimages = [];
    for (let i = 0; i < videoalbum.videos.length; i++) {
      let id;
      if (videoalbum.videos[i].match(/watch\?v=([a-zA-Z0-9\-_]+)/)) {
        id = videoalbum.videos[i].substring(videoalbum.videos[i].indexOf("=") + 1);
        this.createYoutubeVideo(id,"withAlbum");
      }
      else {
        id = videoalbum.videos[i].substring(videoalbum.videos[i].indexOf("d/") + 1).replace('/', '');
        this.createYoutubeVideo(id,"withAlbum");
      }
    }
    this.videoalbum = true;
    this.videoalbumimage = true;
  }

  createYoutubeVideo(id: any,type) {
    let url = "https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=" + id + "&format=json";
    this.http.get(url, {
    }).subscribe((response: any) => {
      var data = response;
      var videotitle = data.title;
      var json = {
        linkimg: 'https://img.youtube.com/vi/' + id + '/hqdefault.jpg',
        title: videotitle,
        link: 'https://www.youtube.com/watch?v=' + id,
      }
      this.videoalbumimages.push(json);
      if (type == "withoutAlbum") {
        this.withoutAlbumImages = this.videoalbumimages.reverse();
      } else {
        this.video = this.videoalbumimages.reverse();
      }
    })
  }

  back() {
    this.videoalbum = false;
    this.videoalbumimage = false;
  }
}
