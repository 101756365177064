<header id="home" class="p-3 d-none d-lg-block">
  <div class="container-fluid">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-1" *ngFor="let data of logo|orderBy:'listingorder'; let i= index">
          <img class="logo" *ngIf="data.images[0]" [src]="imgUrl+data.images[0].filepath">
        </div>
        <div class="col-sm-8 pl-3">
          <div *ngFor="let data of departmentName|orderBy:'listingorder'; let i= index">
            <h4 *ngIf="data">{{data.title}}</h4>
          </div>
          <div *ngFor="let data of govtName|orderBy:'listingorder'; let i= index">
            <h5 *ngIf="data">{{data.title}}</h5>
          </div>    
         
        </div>
        <div class="col-sm-3 pt-3">
          <div class="pull-right mr-4 text-center">
            <a href="http://odc.sikkim.gov.in/" class="text-light font-weight-bold mr-3" target="_blank">
             Planning ODC
            </a>
            <a class="login-btn" href='{{loginUrl}}' target="_blank">Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
