import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-north-east-vision',
    templateUrl: './north-east-vision.component.html',
    styleUrls: ['./north-east-vision.component.scss']
})
/** north-east-vision component*/
export class NorthEastVisionComponent {
  /** nec-guidelines ctor */
  northEastVision: any;
  route: any;
    MenuList: any;
  title: any;
  showhide: boolean;
  fileUrl: any;
  itemsPerPage: number;
  Search: any;
  paginationPageNumber: number;
  northEastVisionCount: any;
  currentDate: any = new Date();
  modeltitle: any;
  content: any;
  image: any;
  multipleEntry: boolean;
    necSpinner: boolean;
  constructor(private spinner:NgxSpinnerService,private appComponent: AppComponent, private http: HttpClient, private router: Router, private datePipe: DatePipe, private orderpipe: OrderPipe) {
    this.fileUrl = window["getfile"];
    var str = this.router.url;
    this.route = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }
  ngOnInit() {
    this.spinner.show();
    this.paginationPageNumber = 1;
    this.itemsPerPage = 5;
    this.getMenuName();
    this.getNorthEastVision();
    
  }

  search() {
    this.paginationPageNumber = 1;
  }

  getMenuName() {
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/', {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.link == this.route);
      this.title = this.MenuList[0].title;
      this.multipleEntry = this.MenuList[0].multipleEntry;
    });
  }
  getNorthEastVision() {
    this.necSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/' + this.route, {
    }).subscribe((data: any) => {
      this.northEastVision = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);   
      this.northEastVisionCount = this.northEastVision.length;
      this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd' + 'T00:00:00');
      this.necSpinner = false;
    });
  }
  itemPerPagePagination(event: any) {
    this.itemsPerPage = event.target.value;
  }
}
