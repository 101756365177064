import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { OrderPipe } from 'ngx-order-pipe';
import { AppComponent } from '../app.component';
import * as XLSX from "xlsx";
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
/** home component*/
export class HomeComponent implements OnInit {
  carouselList: any;
  shortDescription: any;
  image1: any;
  cardList: any;
  contact: any;
  content: any;
  otherLinks: any = [];
  importantLinks: any;
  imgUrl: any;
  albumList: any;
  videoalbumimg: any = [];
  videoalbumList: any;
  videoalbumimages: any = [];
  videoalbumtitle: any = '';
  videoalbum: boolean = false;
  videoalbumimage: boolean = false;
  data: any;
  MenuList: any;
  subMenuList: any;
  title: any;
  circularandnotification: any='';
  notice: any='';
  sif: any='';
  currentDate: any = new Date();
  fileUrl: any;
  cardcontent: any;
  id: any;
  reverse: any;
    videos: any;
  minDate: string;
  Search: any;
  showTooltip: boolean;
  SifmsReportingData: any = [];
  showNoData: boolean;
  displayMessagePopUp: any = 'Information: There is no internet connection';
  filterData: any;
  sifmsReportingDataCount: any;
  isDesc: boolean = false;
  column: string = "CategoryName";
  paginationPageNumber: number = 1;
  tempData: any = [];
  filterdata: any = {};
  messagePopUp: boolean = false;
  allDataload: boolean = false;
  fromdate: any;
  todate: any;
  dept: any;
  storeStartProjectPermission: string;
  financialRelease: any;
  financialReleaseURL: any;
  financialReleaseFilterURL: any;

  circularSpinner: boolean;
  carouselSpinner: boolean;
  cardSpinner: boolean;
  sifSpinner: boolean;
  noticeSpinner: boolean;
  albumSpinner: boolean;
  videoSpinner: boolean;
    onGoingProjects: any=[];
    onGoingProjectsCount: any;
    tempOnGoingProjects: any;

  /** home ctor */
  constructor(private spinner: NgxSpinnerService, private appComponent: AppComponent, private http: HttpClient, private datePipe: DatePipe,private orderpipe: OrderPipe) {
    this.imgUrl = window["orginalImgUrl"];
    this.fileUrl = window["getfile"];
    this.financialReleaseURL = window['financialReleaseURL'];
    this.financialReleaseFilterURL = window['financialReleaseFilterURL'];
  }
  ngOnInit() {
    this.spinner.show();
    $(document).ready(function () {
      $('.next').click(function () { $('.carousel').carousel('next'); return false; });
      $('.prev').click(function () { $('.carousel').carousel('prev'); return false; });
      $('.next').click(function () { $('.photoCarousal').carousel('next'); return false; });
      $('.prev').click(function () { $('.photoCarousal').carousel('prev'); return false; });
    });
    this.filterdata.dept = null;
    this.getfilterbydepartmentData();
    this.getCarouselImage();
    this.getCardImage();
    this.getcircularandnotification();
    this.getNotice();
    this.getSIF();
    this.getvideoalbum();
    this.getAlbumCoverPage();
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd' + 'T00:00:00');
    let now = new Date();
    let past30days = new Date(now.setDate(now.getDate() - 30));
    this.minDate = this.datePipe.transform(past30days, 'yyyy-MM-dd' + 'T00:00:00');
  }

  search() {
    this.paginationPageNumber = 1;
  }

  getCarouselImage() {
    this.carouselSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByMenuName/Carousel', {
    }).subscribe((data: any) => {
      this.carouselSpinner = false;
      this.carouselList = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }

  getCardImage() {
    this.cardSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByMenuName/Dignities Message', {
    }).subscribe((data: any) => {
      this.cardSpinner = false;
      this.cardList = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }

  getcircularandnotification() {
    this.circularSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/circularandnotification', {
    }).subscribe((data: any) => {
      this.circularSpinner = false;
      this.circularandnotification = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }
  
  getNotice() {
    this.noticeSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/notice-board', {
    }).subscribe((data: any) => {
      this.noticeSpinner = false;
      this.notice = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);    
    });
  }

  getSIF() {
    this.sifSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/sif', {
    }).subscribe((data: any) => {
      this.sifSpinner = false;
      this.sif = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);    
    });
  }


  getAlbumCoverPage() {
    this.albumSpinner = true;
      this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/photo-gallery', {
      }).subscribe((data: any) => {
        this.albumSpinner = false;
        this.albumList = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
        console.log(this.albumList)
      }
        , (err) => {
        });
    }

  getvideoalbum() {
    this.videoSpinner = true;
      this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/video-gallery', {
      }).subscribe((data: any) => {
        this.videoSpinner = false;
        this.videoalbumList = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
        this.videoalbumimages = [];
        for (let i = 0; i < this.videoalbumList.length; i++) {
          var albumtitle = this.videoalbumList[i].albumname;
          var id = this.videoalbumList[i].id;
          this.getvideoalbumImages(id, albumtitle);
        }
      }
        , (err) => {
        });
    }

  getvideoalbumImages(id: any, albumtitle: any) {
      this.http.get(this.appComponent.APIUrl + 'Post/GetPostById/' + id, {
      }).subscribe((data: any) => {
        var postdate = data.postDTO[0].postdate;
        this.videoalbumtitle = data.postDTO[0].title;
        this.data = data.postDTO[0].video.split(",");
        let youtubeid = this.data[0].substring(this.data[0].indexOf("=") + 1);
        this.createYoutubeVideo(youtubeid, albumtitle, postdate);
        this.videoalbum = true;
        this.videoalbumimage = true;
      })
    }

  createYoutubeVideo(id: any, albumtitle: any, postdate: any) {
      let url = "https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=" + id + "&format=json";
      this.http.get(url, {
      }).subscribe((response: any) => {
        var data = response;
        var videotitle = data.title;
        var authorname = data.author_name;
        var json = {
          albumtitle: albumtitle,
          linkimg: 'https://img.youtube.com/vi/' + id + '/hqdefault.jpg',
          title: videotitle,
          link: 'https://www.youtube.com/watch?v=' + id,
          authorname: authorname,
          postdate: postdate
        }
        this.videoalbumimages.push(json);
        this.videos = this.videoalbumimages.reverse();
      })
    }
  GetOngoingProjects() {
    let url = window["AllOngoingProjects"]
      this.http.get(url, {
      }).subscribe((data: any) => {
        this.tempOnGoingProjects = data;
        this.onGoingProjects = [];
        this.onGoingProjectsCount = this.onGoingProjects.length;
      });
    
  }
  getSifmsReportingData( limitFrom: any, limitTo: any) {
    if (this.sifmsReportingDataCount == undefined) {
    }
    let url = this.financialReleaseURL + limitFrom + '/' + limitTo;
    this.http.get(url, {
    }).subscribe(
      (data: any) => {
        let $this = this;
        if (data.sifsRecords.length != 0) {
          data.sifsRecords.forEach(function (item) {
            $this.tempData.push(item);
          })
          if (this.fromdate != null && this.todate != null && this.dept == null) {
            this.SifmsReportingData = this.tempData.filter(
              (x) => formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") >= this.fromdate && formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") <= this.todate
            );
            this.sifmsReportingDataCount = this.SifmsReportingData.length;
            if (this.SifmsReportingData.length == 0) {
              this.showNoData = true;
            } else {
              this.showNoData = false;
            }
          }
          else if (this.dept != null && this.fromdate == null && this.todate == null) {
            this.SifmsReportingData = this.tempData.filter(
              (x) => parseInt(x.departmentcode) == parseInt(this.dept)
            );
            this.sifmsReportingDataCount = this.SifmsReportingData.length;
            if (this.SifmsReportingData.length == 0) {
              this.showNoData = true;
            } else {
              this.showNoData = false;
            }
          }
          else if (this.fromdate != null && this.todate != null && this.dept != null) {
            this.SifmsReportingData = this.tempData.filter(
              (x) => parseInt(x.departmentcode) == parseInt(this.dept)
                && formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") >= this.fromdate && formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") <= this.todate
            );
            this.sifmsReportingDataCount = this.SifmsReportingData.length;
            if (this.SifmsReportingData.length == 0) {
              this.showNoData = true;
            } else {
              this.showNoData = false;
            }
          }
          else {
            this.SifmsReportingData = this.tempData;
            this.sifmsReportingDataCount = this.SifmsReportingData.length;

          }
          if (data.total >= this.sifmsReportingDataCount) {
            limitTo = limitTo * 2;
            limitFrom = this.sifmsReportingDataCount + 1;
            this.getSifmsReportingData( limitFrom, limitTo);
          }
          else {
            this.allDataload = true;
          }
        }
        else {
          this.allDataload = true;
          this.showNoData = true;
        }

      },
      (err) => {
        //this.messagePopUp = true;
        //this.displayMessagePopUp = err.Message.includes("Source#")
        //  ? err.Message
        //  : MessageVariable.ErrorMsg;
      }
    );
  }
  closeMessagePopUp() {
    this.messagePopUp = false;
    this.showNoData = false;
  }


  FilterData(Parameter: any) {
    this.dept = Parameter.dept;
    if (Parameter.fromdate == undefined || Parameter.todate == undefined) {
      this.fromdate = null;
      this.todate = null;
    } else {
      this.fromdate = formatDate(Parameter.fromdate, "yyyy-MM-dd", "en-US", "+0530");
      this.todate = formatDate(Parameter.todate, "yyyy-MM-dd", "en-US", "+0530");
    }
    if ((this.fromdate == null || this.todate == null) && (Parameter.dept == null || Parameter.dept == "null")) {
      this.messagePopUp = true;
      this.displayMessagePopUp =
        "Information: Please select either From Date and To Date or Department.";
    }
    if (this.fromdate > this.todate) {
      this.messagePopUp = true;
      this.displayMessagePopUp =
        "Information: Release From Date should not be greater than To Date";
    } else {

      if (this.fromdate != null && this.todate != null && this.dept == null) {
        this.SifmsReportingData = this.tempData.filter(
          (x) => formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") >= this.fromdate && formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") <= this.todate
        );
        this.sifmsReportingDataCount = this.SifmsReportingData.length;
        if (this.SifmsReportingData.length == 0) {
          this.showNoData = true;
        } else {
          this.showNoData = false;
        }
      }
      else if (this.dept != null && this.fromdate == null && this.todate == null) {
        this.SifmsReportingData = this.tempData.filter(
          (x) => parseInt(x.departmentcode) == parseInt(this.dept)
        );
        this.sifmsReportingDataCount = this.SifmsReportingData.length;
        if (this.SifmsReportingData.length == 0) {
          this.showNoData = true;
        } else {
          this.showNoData = false;
        }
      }
      else if (this.fromdate != null && this.todate != null && this.dept != null) {
        this.SifmsReportingData = this.tempData.filter(
          (x) => parseInt(x.departmentcode) == parseInt(this.dept)
            && formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") >= this.fromdate && formatDate(x.dateofpayment, "yyyy-MM-dd", "en-US", "+0530") <= this.todate
        );
        this.sifmsReportingDataCount = this.SifmsReportingData.length;
        if (this.SifmsReportingData.length == 0) {
          this.showNoData = true;
        } else {
          this.showNoData = false;
        }
      }
    }
  }
  FilterOnGoingProjects(filterData) {
    if (filterData.dept == undefined || filterData.dept == "null") {
      alert("Please select department name.")
    }
    else if (filterData.dept == 'allDepartment')
    {
      this.onGoingProjects = this.tempOnGoingProjects;
      this.sifmsReportingDataCount = this.SifmsReportingData.length;
    }
    else {
      this.onGoingProjects = this.tempOnGoingProjects.filter(
        (x) => x.departmentid == filterData.dept
      );
      this.sifmsReportingDataCount = this.SifmsReportingData.length;
    }
   
  }
  ExportTOExcel() {
    if (this.allDataload == false) {
      this.messagePopUp = true;
      this.displayMessagePopUp =
        "Information: Data not loaded completly..";
    }
    else {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.SifmsReportingData
      );
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(
        wb,
        "SIFMSReport_" +
        formatDate(new Date(), "ddMMMyyyy", "en-US", "+0530") +
        ".xlsx"
      );
    }

  }

  getfilterbydepartmentData() {
    this.http.get(this.financialReleaseFilterURL, {
    }).subscribe(
      (data) => {
        this.filterData = data;
        this.GetOngoingProjects();
      },
      (err) => {
        this.messagePopUp = true;
      
      }
    );
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.onGoingProjects.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  itemsPerPage: any = 10;
  itemPerPagePagination(event: any) {
    this.itemsPerPage = event.target.value;
  }
  Clear() {
    this.filterdata = {};
    this.todate = null;
    this.fromdate = null;
    this.dept = null;
    this.filterdata.dept = null;
    this.SifmsReportingData = this.tempData;
    this.sifmsReportingDataCount = this.SifmsReportingData.length;
    if (this.SifmsReportingData.length == 0) {
      this.showNoData = true;
    } else {
      this.showNoData = false;
    }
  }
  ClearOnGoingProjectFilter() {
    this.filterdata.dept=null;
    this.onGoingProjects = [];
    this.onGoingProjectsCount = this.onGoingProjects.length;
  }
}
