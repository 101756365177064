import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  apiUrl: any;
  menuList: any = [];
  imgUrl: any;
  logo: any;
  departmentName: any;
  govtName: any;
  mainmenu: any = [];
  hold: any = [];
  submenulistitems: any = '';
  mainmenuid: any = '';
  urlActive: boolean = true;
    loginUrl: any;
  constructor(private appComponent: AppComponent, private http: HttpClient, private router: Router) {
    this.imgUrl = window["orginalImgUrl"];
    this.loginUrl = window["HeadlessUI"];
  }

  ngOnInit() {
    this.getmenu();
    var screenwidth = $(window).width();
    if (screenwidth < 1024) {
      this.getLogoImage();
      this.getDepartmentName();
      this.getGovtName();
    }
    $(document).ready(function () {
      $('.navbar-nav>li>a').click( function () {
        $('.nav-menu').removeClass('show');
      });
    })
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  getmenu() {
    debugger;
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/', {
    }).subscribe((data: any) => {
      if (!data.hasError) {        
        this.menuList = data.menuMasterDTO.filter(x => x.status == 'Active' && x.displayonhome == true);
      }
      else {
        // TODO: handle error
      }
      //modified by: Leela Ballavh Nepal
      //Date: 6-Dec-2021
      let $this = this;
      this.mainmenu = this.menuList.filter(x => x.parentId == 0);
      this.mainmenu.forEach(function(menu)
      {
        menu.submenuList = [];
        if (menu.submenu)
          menu.submenuList= $this.menuList.filter(x => x.parentId == menu.id)
      })
      
    
      //this.menuList.forEach(function (val) {
      //  if (val.submenu) {
      //    val.submenuList = [];
      //    $this.mainmenu.push(val);         
      //  }
      //  else if (val.parentId != 0) {
      //    $this.hold.push(val);
      //    $this.mainmenu.forEach(function (mainMenuItem) {    
      //        if (val.parentId == mainMenuItem.id) {
      //          mainMenuItem.submenuList.push(val);
      //        }
      //    }); 
      //  }
      //  else {
      //    if (val.parentId == 0) {
      //      $this.mainmenu.push(val);           
      //    }
      //    //$this.hideshow(val.link);
      //  }
      //})
      //console.log(this.mainmenu)
    });
  }

  getLogoImage() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByCategory/Logo', {
    }).subscribe((data: any) => {
      this.logo = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
      
    });
  }

  getDepartmentName() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByCategory/Department Name', {
    }).subscribe((data: any) => {
      this.departmentName = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }

  getGovtName() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByCategory/Government Of', {
    }).subscribe((data: any) => {
      this.govtName = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }

  hideshow(url: any) {
    var re = /#/gi;
    var str = url;
    if (str.search(re) == -1) {
      this.urlActive = true;

    } else {
      this.urlActive = false;
    } 
  }
}
