<section id="contact" class="cta pt-5 pb-5">
  <div class="container">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12" style=" margin-top: 16rem !important;" *ngIf="contactSpinner && otherLinksSpinner && importantLinksSpinner">
          <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#fff" type="ball-elastic-dots"
                       [fullScreen]="false"><p style="padding-top:100px!important;color:white">loading</p></ngx-spinner>

        </div>
        <div class="col-lg-4 mt-3" *ngFor="let data of contact|orderBy :'listingorder'">
          <h2>{{data.title}}</h2>
          <div class="aos-init aos-animate" data-aos="zoom-in" [innerHtml]="data.content|sanitizeHtml">

          </div>
        </div>
        <div class="col-lg-4 imt mt-3" *ngFor="let data of importantLinks|orderBy :'listingorder'">
          <h2>{{data.title}}</h2>
          <div class="w-100 content" [innerHtml]="data.content|sanitizeHtml">
          </div>
        </div>
        <div class="col-lg-4 imt mt-3" *ngIf="otherLinks.length>0">
          <h2>{{title}}</h2>
          <div class="w-100 content">
            <ul class="navbar-nav">
              <li class="nav-item" *ngFor="let data of otherLinks|orderBy :'listingorder'">
                <a class="text-uppercase" href="{{data.link}}" [routerLinkActive]='["link-active"]' [routerLink]='"/"+data.link'>{{data.title}}</a>

              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
  </div>
</section>
