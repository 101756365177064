import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  APIUrl: any = '';

  constructor(private http: HttpClient) {
    this.APIUrl = '';
    this.APIUrl = window["APIURL"];
  }
    ngOnInit(){
      $(document).ready(function () {
        $(window).scroll(function () {
          if ($(this).scrollTop() > 20) {
            $('#toTopBtn').fadeIn();
          } else {
            $('#toTopBtn').fadeOut();
          }
        });

        $('#toTopBtn').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 1000);
          return false;
        });
      });
    }

  
}
