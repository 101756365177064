<section class="pt-5 pb-5" *ngIf="sifSpinner">
  <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots"
               [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
</section>
<section class="pt-5 pb-5" *ngIf="sif=='' && !sifSpinner">
  <div class="container">
    <div class="col-lg-6"> <h2>{{title}}</h2></div>
    <div class="container pt-3 pb-3 box">Content Awaited !!!</div>
  </div>
</section>
<section class="pt-5 pb-5" *ngIf="sif!=''  && !sifSpinner">
  <div class="container">
    <div class="row" *ngIf="multipleEntry">
      <div class="col-lg-6"> <h2>{{title}}</h2></div>
      <div class="col-lg-6"><input type="text" [(ngModel)]="Search" (click)="search()" class="form-control float-right" placeholder="Search"></div>
    </div>
    <div class="bg-white text-dark ">
      <div class="container pt-3 pb-3 box" *ngIf="!sif">Contents Awaited !!!</div>
      <div *ngIf="!multipleEntry">
        <div *ngFor="let data of sif|orderBy :'listingorder'; let i= index">
          <div *ngIf="sif" class="container mb-5">
            <h2>{{data.title}}</h2>

            <div *ngIf="data.content" class="container pt-3 pb-3 bg-white text-dark box">
              <div class="table-responsive" [innerHtml]="data.content |sanitizeHtml">
              </div>
              <div *ngIf="data.images.length>0">
                <img class="img-thumbnail" *ngIf="data.images[0].filepath.split('.').reverse()[0]=='webp'" [src]="imgUrl+data.images[0].filepath" />
                <ul class="ml-4" *ngIf="data.images[0].filepath.split('.').reverse()[0]!='webp'">
                  <li>
                    <a target="{{data.images[0].filepath.split('.').reverse()[0]=='pdf'?'_blank':''}}" href="{{fileUrl+data.images[0].filepath}}">
                      {{data.title}}
                    </a> <i class="fa fa-download mr-2" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
            </div>

            <div *ngIf="!data.content" class="container pt-3 pb-3 bg-white text-dark box">
              <div *ngIf="data.images.length>0">
                <img class="img-thumbnail" *ngIf="data.images[0].filepath.split('.').reverse()[0]=='webp'" [src]="imgUrl+data.images[0].filepath" />
                <ul class="ml-4" *ngIf="data.images[0].filepath.split('.').reverse()[0]!='webp'">
                  <li>
                    <a target="{{data.images[0].filepath.split('.').reverse()[0]=='pdf'?'_blank':''}}" href="{{fileUrl+data.images[0].filepath}}">
                      {{data.title}}
                    </a> <i class="fa fa-download mr-2" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="multipleEntry">
        <table class="table table-striped" *ngIf="sif">
          <tbody>
            <tr>
              <td *ngIf="(sif | filter:Search).length==0 ">
                <p>No Data Found !!!</p>
              </td>
            </tr>
            <tr *ngFor="let data of sif|orderBy:'listingorder'|filter:Search|paginate: { itemsPerPage: itemsPerPage,currentPage: paginationPageNumber };let i=index">
              <td class="corner-cut corner-danger">
                <div *ngIf="data.content">
                  <a data-toggle="modal" data-target="#newContent" (click)="modeltitle=data.title;content=data.content;image=data.images.lenght>0?data.images[0].filepath: ''">
                    <span class="new-cus" *ngIf="currentDate==data.createdOn">New</span>
                    <div class="col-md-12 news-head" style="font-family:Cambria">
                      <div class="row">
                        <div class="col-lg-1 d-none d-lg-block h-100">
                          <img class="img-fluid news-img" *ngIf="data.content" src="../../../assets/Images/note.png" />
                        </div>
                        <div class="col-lg-11">
                          <h6 class="w-100">
                            {{data.title}}
                          </h6>
                          <h6>{{data.author}}</h6>
                          <div class="row">
                            <div class="col-md-2">
                              <i class="fa fa-calendar mr-2 pull-left" aria-hidden="true"></i><i>{{data.createdOn | date}}</i>
                            </div>
                            <div class="col-md-10">
                              <span class="pull-right">
                                <i class="fa fa-info-circle mr-2" aria-hidden="true"></i> Read More..
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div *ngIf="!data.content">
                  <a href="{{fileUrl+data.images[0].filepath}}" target="{{data.images[0].filepath.split('.').reverse()[0]=='pdf'?'_blank':''}}">
                    <span class="new-cus" *ngIf="currentDate==data.createdOn">New</span>
                    <div class="col-md-12 news-head" style="font-family:Cambria">
                      <div class="row">
                        <div class="col-lg-1 d-none d-lg-block h-100 p-3" *ngIf="data.images.length>0">
                          <img class="img-fluid news-img" *ngIf="data.images[0].filepath.split('.').reverse()[0]=='xlsx'" src="../../../assets/Images/xml.jpg" />
                          <img class="img-fluid news-img" *ngIf="data.images[0].filepath.split('.').reverse()[0]=='xml'" src="../../../assets/Images/xml.jpg" />
                          <img class="img-fluid news-img" *ngIf="data.images[0].filepath.split('.').reverse()[0]=='pdf'" src="../../../assets/Images/pdf1.png" />
                        </div>
                        <div class="col-lg-11">
                          <h6 class="w-100">{{data.title}}</h6>
                          <h6>{{data.author}}</h6>
                          <div class="row">
                            <div class="col-md-2">
                              <i class="fa fa-calendar mr-2 pull-left" aria-hidden="true"></i><i>{{data.createdOn | date}}</i>
                            </div>
                            <div class="col-md-10" *ngIf="data.images.length>0">
                              <span class="pull-right">
                                <i class="fa fa-download mr-2" aria-hidden="true"></i> Download
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-md-12 text-center mt-3" *ngIf="sif">
          <div class="row">
            <div align="right" class="col-md-4" style="margin-right: -20px;">
              Page
              <select style="width: 44px;" (change)="itemPerPagePagination($event)" id="pageItem">
                <option value="5">5</option>
                <option *ngIf="sifCount>5" value="10">10</option>
                <option *ngIf="sifCount>10" value="25">25</option>
                <option *ngIf="sifCount>25" value="50">50</option>
                <option *ngIf="sifCount>50" value="75">100</option>
                <option *ngIf="sifCount>100" value="{{sifCount}}">{{sifCount}}</option>
              </select>
            </div>
            <div align="left" class="col-md-8">
              <pagination-controls (pageChange)="paginationPageNumber = $event"></pagination-controls>
            </div>
          </div>
        </div>
        <div class="modal fade" id="newContent">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                <h6 class="modal-title">{{modeltitle}}</h6>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div *ngIf="image">
                <a class="modal-header bg-primary" style="color: #fff !important; text-decoration: none;"
                   *ngIf="image.split('.').reverse()[0]!='webp'" href="{{fileUrl+image}}"
                   target="_blank">
                  {{modeltitle+'.'+image.split('.').reverse()[0]}}
                  <i class="fa fa-download mr-2" aria-hidden="true"></i>
                </a>
              </div>
              <!-- Modal body -->
              <div class="modal-body mb-5">
                <p *ngIf="image" style="float:left;padding-right:30px;margin-bottom:0px;">
                  <img class="img-thumbnail" *ngIf="image.split('.').reverse()[0]=='webp'" [src]="imgUrl+image" alt="" style="max-width: 27rem;">
                </p>
                <p *ngIf="!content">Contents Awaited !!!</p>
                <p *ngIf="content" class="section-excerpt " style="text-align:justify;padding-bottom: 30px;float: initial;"
                   [innerHtml]="content|sanitizeHtml">
                </p>
                <!--<div [innerHtml]="content|sanitizeHtml"></div>-->
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
