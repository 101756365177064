<section class="pt-5 pb-5" *ngIf="nlcprSpinner">
  <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots"
               [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
</section>
  <section class="pt-5 pb-5" *ngIf="nlcpr==''&& !nlcprSpinner">
    <div class="container">
      <div class="col-lg-6"> <h2>{{title}}</h2></div>
      <div class="container pt-3 pb-3 box">Contents Awaited !!!</div>
    </div>
  </section>
  <section class="pt-5 pb-5 container" *ngIf="nlcpr!=''&& !nlcprSpinner">
    <div class="row">
      <div class="col-lg-6"> <h2>{{title}}</h2></div>
      <div class="col-lg-6"><input type="text" [(ngModel)]="Search" (click)="search()" class="form-control float-right" placeholder="Search"></div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-bordered border">
        <thead>
          <tr>
            <th>Sl.No.</th>
            <th>Project Name</th>
            <th>Implementing Department</th>
            <th>Project Value(In Thousands)</th>
            <th>Sanctioned Year</th>
            <th>Physical Progress(%)</th>
            <th>Financial Progress(%)</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngIf="(nlcpr | filter:Search).length==0">
            <td colspan="9">Sorry! No record found...</td>
          </tr>
          <tr *ngFor="let data of nlcpr | filter:Search | paginate: { itemsPerPage: itemsPerPage, currentPage: paginationPageNumber };index as i">
            <td style="text-align: center;">{{ (paginationPageNumber - 1) * itemsPerPage + i +1 }}</td>
            <td style="text-align: center;">{{data.projectname}}</td>
            <td style="text-align: center;">{{data.departmentname}}</td>
            <td style="text-align: center;">{{data.sanctionedamount}}</td>
            <td style="text-align: center;">{{data.sanctionedyear| date : 'dd-MMM-yyyy'}}</td>
            <td style="text-align: center;">{{data.physicalprogress }}</td>
            <td style="text-align: center;">{{data.financialprogress }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12 text-center mt-3" *ngIf="nlcpr">
      <div class="row">
        <div align="right" class="col-md-4" style="margin-right: -20px;">
          Page
          <select style="width: 44px;" (change)="itemPerPagePagination($event)" id="pageItem">
            <option value="10">10</option>
            <option *ngIf="nlcprCount>10" value="25">25</option>
            <option *ngIf="nlcprCount>25" value="50">50</option>
            <option *ngIf="nlcprCount>50" value="100">100</option>
            <option *ngIf="nlcprCount>100" value="125">125</option>
            <option *ngIf="nlcprCount>125" value="{{nlcprCount}}">{{nlcprCount}}</option>
          </select>
        </div>
        <div align="left" class="col-md-8">
          <pagination-controls (pageChange)="paginationPageNumber = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </section>
