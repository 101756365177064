<button id="show-sidebar" class="btn btn-sm btn-dark text-center toggled">
  <h6 class="linkcontant">Important Links</h6>
</button>
<nav id="sidebar" class="sidebar-wrapper">
  <div class="sidebar-content">
    <div class="sidebar-brand">
      <div id="close-sidebar" class="w-100">
        <i class="fa fa-times pull-right text-light"></i>
      </div>
    </div>
    <div class="sidebar-header">

      <div class="side" *ngIf="otherLinks">
        <div class="header-menu border-bottom mb-2">
          <span>{{title}}</span>
        </div>
        <div class="contents">
          <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let data of otherLinks">
              <a class="text-uppercase" href="{{data.link}}" [routerLinkActive]='["link-active"]' [routerLink]='"/"+data.link'>{{data.title}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="side" *ngFor="let data of importantLinks|orderBy :'listingorder'">
        <div class="header-menu border-bottom mb-2" >
          <span>{{data.title}}</span>
        </div>
        <div class="content" [innerHtml]="data.content|sanitizeHtml">
  </div>
      </div>
    </div>
  </div>
</nav>
