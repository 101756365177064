import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { OrderPipe } from 'ngx-order-pipe';
import { AppComponent } from '../../app.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-nlcpr',
    templateUrl: './nlcpr.component.html',
    styleUrls: ['./nlcpr.component.scss']
})
/** nlcpr component*/
export class NlcprComponent {
    /** nlcpr ctor */
  nlcpr: any = '';
  route: any;
  MenuList: any;
  title: any;
  fileUrl: any;
  itemsPerPage: number;
  Search: any;
  paginationPageNumber: number;
  nlcprCount: any;
  currentDate: any = new Date();
  modeltitle: any;
  content: any;
  image: any;
  multipleEntry: boolean = false;
  imgUrl: any;
  url: any;
  nlcprSpinner: boolean;
  constructor(private spinner: NgxSpinnerService,private appComponent: AppComponent, private http: HttpClient, private router: Router, private datePipe: DatePipe, private orderpipe: OrderPipe) {
    this.imgUrl = window["orginalImgUrl"];
    this.fileUrl = window["getfile"];
    this.url = window['NLCPR']
    var str = this.router.url;
    this.route = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }
  ngOnInit() {
    this.spinner.show();
    this.paginationPageNumber = 1;
    this.itemsPerPage = 10;
    this.getMenuName();
    this.getNLCPR();

  }

  search() {
    this.paginationPageNumber = 1;
  }

  getMenuName() {
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/', {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.link == this.route);
      this.multipleEntry = this.MenuList[0].multipleEntry;
      this.title = this.MenuList[0].title;
    });
  }

  getNLCPR() {
    if (this.url != '') {
      this.nlcprSpinner = true;
      this.http.get(this.url, {
      }).subscribe((data: any) => {
        this.nlcprSpinner = false;
        this.nlcpr = data;
        this.nlcprCount = this.nlcpr.length;
      });
    }
  }
  itemPerPagePagination(event: any) {
    this.itemsPerPage = event.target.value;
  }
}
