import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PhotoGalleryComponent } from './gallery/photo-gallery/photo-gallery.component';
import { VideoGalleryComponent } from './gallery/video-gallery/video-gallery.component';
import { StatePlanComponent } from './state-plan/state-plan.component';
import { NecGuidelinesComponent } from './nec/nec-guidelines/nec-guidelines.component';
import { NecOngoingProjectsComponent } from './nec/nec-ongoing-projects/nec-ongoing-projects.component';
import { NecProjectsComponent } from './nec/nec-projects/nec-projects.component';
import { NorthEastVisionComponent } from './nec/north-east-vision/north-east-vision.component';
import { NesidsGuidelinesComponent } from './nesids/nesids-guidelines/nesids-guidelines.component';
import { NesidsProjectsComponent } from './nesids/nesids-projects/nesids-projects.component';
import { NesidsOngoingProjectsComponent } from './nesids/nesids-ongoing-projects/nesids-ongoing-projects.component';
import { BadpGuidelinesComponent } from './badp/badp-guidelines/badp-guidelines.component';
import { BadpProjectsComponent } from './badp/badp-projects/badp-projects.component';
import { BadpOngoingProjectsComponent } from './badp/badp-ongoing-projects/badp-ongoing-projects.component';
import { EapProjectsComponent } from './eap/eap-projects/eap-projects.component';
import { EapOngoingProjectsComponent } from './eap/eap-ongoing-projects/eap-ongoing-projects.component';
import { SdgComponent } from './sdgs/sdg/sdg.component';
import { SifComponent } from './sdgs/sif/sif.component';
import { NewsComponent } from './other-links/news/news.component';
import { EventsComponent } from './other-links/events/events.component';
import { NoticeBoardComponent } from './other-links/notice-board/notice-board.component';
import { CircularandnotificationComponent } from './other-links/circularandnotification/circularandnotification.component';
import { PressReleaseComponent } from './other-links/press-release/press-release.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { SanitizeHtmlPipe } from './sanitizeHtml.pipe';
import { ContactusComponent } from './contactus/contactus.component';
import { OrderModule } from 'ngx-order-pipe';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NlcprComponent } from './other-schemes/nlcpr/nlcpr.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationComponent } from './rti/notification/notification.component';
import { ActComponent } from './rti/act/act.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HeaderComponent,
    FooterComponent,
    SidenavMenuComponent,
    HomeComponent,
    PhotoGalleryComponent,
    VideoGalleryComponent,
    AboutusComponent,
    NlcprComponent,
    StatePlanComponent,
    NecGuidelinesComponent,
    NecOngoingProjectsComponent,
    NecProjectsComponent,
    NorthEastVisionComponent,
    NesidsGuidelinesComponent,
    NesidsProjectsComponent,
    NesidsOngoingProjectsComponent,
    BadpGuidelinesComponent,
    BadpProjectsComponent,
    BadpOngoingProjectsComponent,
    EapProjectsComponent,
    EapOngoingProjectsComponent,
    SdgComponent,
    SifComponent,
    NewsComponent,
    EventsComponent,
    NoticeBoardComponent,
    CircularandnotificationComponent,
    PressReleaseComponent,
    ContactusComponent,
    SanitizeHtmlPipe,
    NotificationComponent,
    ActComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    OrderModule,
    NgxSpinnerModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'aboutus', component: AboutusComponent },
      { path: 'photo-gallery', component: PhotoGalleryComponent },
      { path: 'video-gallery', component: VideoGalleryComponent },
      { path: 'nlcpr', component: NlcprComponent },
      { path: 'state-plan', component: StatePlanComponent },
      { path: 'nec-guidelines', component: NecGuidelinesComponent },
      { path: 'nec-ongoing-projects', component: NecOngoingProjectsComponent },
      { path: 'nec-projects', component: NecProjectsComponent },
      { path: 'north-east-vision', component: NorthEastVisionComponent },
      { path: 'nesids-guidelines', component: NesidsGuidelinesComponent },
      { path: 'nesids-projects', component: NesidsProjectsComponent },
      { path: 'nesids-ongoing-projects', component: NesidsOngoingProjectsComponent },
      { path: 'badp-guidelines', component: BadpGuidelinesComponent },
      { path: 'badp-projects', component: BadpProjectsComponent },
      { path: 'badp-ongoing-projects', component: BadpOngoingProjectsComponent },
      { path: 'eap-projects', component: EapProjectsComponent },
      { path: 'eap-ongoing-projects', component: EapOngoingProjectsComponent },
      { path: 'sdg', component: SdgComponent },
      { path: 'sif', component: SifComponent },
      { path: 'news', component: NewsComponent },
      { path: 'events', component: EventsComponent },
      { path: 'notice-board', component: NoticeBoardComponent },
      { path: 'circularandnotification', component: CircularandnotificationComponent },
      { path: 'press-release', component: PressReleaseComponent },
      { path: 'contactus', component: ContactusComponent },
      { path: 'notification', component: NotificationComponent },
      { path: 'act', component: ActComponent },
    ], { useHash: true })
  ],
  providers: [DatePipe, NgxSpinnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
