import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-eap-ongoing-projects',
    templateUrl: './eap-ongoing-projects.component.html',
    styleUrls: ['./eap-ongoing-projects.component.scss']
})
/** eap-ongoing-projects component*/
export class EapOngoingProjectsComponent {
    /** eap-ongoing-projects ctor */
  eapOngoingProjects: any='';
  route: any;
    MenuList: any;
    title: any;
    fileUrl: any;
  itemsPerPage: number;
  Search: any;
  paginationPageNumber: number;
  eapOngoingProjectsCount: any;
  currentDate: any = new Date();
  modeltitle: any;
  content: any;
  image: any;
  multipleEntry: boolean = false;
  imgUrl: any;
  url: any;
  eapSpinner: boolean;
  constructor(private spinner: NgxSpinnerService,private appComponent: AppComponent, private http: HttpClient, private router: Router, private datePipe: DatePipe, private orderpipe: OrderPipe) {
    this.imgUrl = window["orginalImgUrl"];
    this.fileUrl = window["getfile"];
    this.url = window['EAPOngoingProjects'];
    var str = this.router.url;
    this.route = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }
  ngOnInit() {
    this.spinner.show();
    this.paginationPageNumber = 1;
    this.itemsPerPage = 10;
    this.getMenuName();
    this.getEAPOngiongProjects();
    
  }

  search() {
    this.paginationPageNumber = 1;
  }

  getMenuName() {
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/', {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.link == this.route);
      this.title = this.MenuList[0].title;
      this.multipleEntry = this.MenuList[0].multipleEntry;
    });
  }
  getEAPOngiongProjects() {
    if (this.url != '') {
      this.eapSpinner = true;
      this.http.get(this.url, {
      }).subscribe((data: any) => {
        this.eapSpinner = true;
        this.eapOngoingProjects = data;
        this.eapOngoingProjectsCount = this.eapOngoingProjects.length;
      });
    }
    
  }
  itemPerPagePagination(event: any) {
    this.itemsPerPage = event.target.value;
  }
}
