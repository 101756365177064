import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})

/** photo-gallery component*/
export class PhotoGalleryComponent implements OnInit {
  img: any;
  imgUrl: any;
  MenuList: any;
  title: any;
  route: any = '';
  albumList: any;
  albumimages: any;
  albumtitle: any = '';
  album: boolean = false;
  albumimage: boolean = false;
  imageId: any;
  currentDate: any = new Date();
  multipleEntry: boolean = false;
  active: boolean = false;
  albumSpinner: boolean;
  albumImageSpinner: boolean;
    withoutAlbumList: any;
    withoutAlbumImages: any[];
  /** photo-gallery ctor */
  constructor(private spinner: NgxSpinnerService, private appComponent: AppComponent, private http: HttpClient, private router: Router, private datePipe: DatePipe) {

    var str = this.router.url;
    this.route = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');


  }
  ngOnInit() {
    this.spinner.show();
    this.imgUrl = window['orginalImgUrl'];
    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd' + 'T00:00:00');
    this.getMenu();
    this.getAlbum();
  }


  getMenu() {
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/', {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.link == this.route);
      this.title = this.MenuList[0].title;
      this.multipleEntry = this.MenuList[0].multipleEntry;
    }
      , (err) => {
      });
  }

  albumListGroup: any = [];
  getAlbum() {
    this.albumSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'AlbumMaster/0', {
    }).subscribe((data: any) => {
      this.albumSpinner = false;
      this.albumListGroup = data.albumDTO.filter(x => x.status == 'Active');
      this.getPhotoGallery();
    }
      , (err) => {
      });
  }

  getPhotoGallery() {
    this.albumSpinner = true;
    let $this = this;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/' + this.route, {
    }).subscribe((data: any) => {
      this.albumSpinner = false;
      this.albumList = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
      this.withoutAlbumImages = [];
      data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1 && x.albumid == 0).forEach(function (list) {
        list.images.forEach(function (image) {
          $this.withoutAlbumImages.push(image);
        })
      })
      this.albumListGroup.forEach(function (albumGroup, index) {
        $this.albumListGroup[index].images = [];
        $this.albumList.forEach(function (album) {
          if (albumGroup.id == album.albumid) {
            album.images.forEach(function (image, i) {
              $this.albumListGroup[index].coverimage = $this.albumListGroup[index].coverimage == null ?
                album.images[0].filepath : $this.albumListGroup[index].coverimage;
              $this.albumListGroup[index].images.push(image);
              $this.albumListGroup[index].createdOn = album.updatedOn != '0001-01-01T00:00:00' ? album.updatedOn : album.createdOn;
              $this.albumListGroup[index].listing = $this.datePipe.transform($this.albumListGroup[index].createdOn, "yyyy")
                + $this.datePipe.transform($this.albumListGroup[index].createdOn, "mm")
                + $this.datePipe.transform($this.albumListGroup[index].createdOn, "dd")
            })
          }
        })
      })
      let album = this.albumListGroup.filter(x => x.images.length > 0);
      this.albumList = album;
      console.log(this.withoutAlbumImages)
    }
      , (err) => {
      });
  }

  getAlbumImages(album: any) {
    this.albumImageSpinner = true;
    this.albumimages = album.images;
    this.albumtitle = album.albumname;
    this.album = true;
    this.albumimage = true;
    console.log(this.albumimages)
    this.albumImageSpinner = false;
  }

  back() {
    this.album = false;
    this.albumimage = false;

  }

  showimage(id: any, value: any) {
    this.imageId = '';
    this.imageId = id;
  }

  clear() {
    this.imageId = '';
  }
}
