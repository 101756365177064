import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
/** footer component*/
export class FooterComponent implements OnInit {
/** footer ctor */

  footer: any;
  constructor(private appComponent: AppComponent, private http: HttpClient) {

  }
  ngOnInit() {
    this.getfooter();
    }


  getfooter() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByCategory/Footer', {
    }).subscribe((data: any) => {
      this.footer = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }
}
