import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-sidenav-menu',
    templateUrl: './sidenav-menu.component.html',
    styleUrls: ['./sidenav-menu.component.scss']
})
/** sidenav-menu component*/
export class SidenavMenuComponent implements OnInit {
  otherLinks: any = [];
  importantLinks: any;
  MenuList: any;
  subMenuList: any;
  title: any;
    /** sidenav-menu ctor */
  constructor(private appComponent: AppComponent, private http: HttpClient) {

    }
    ngOnInit() {
      $(document).ready(function () {
          $("#close-sidebar").click(function (e) {
            e.preventDefault();
            $("#show-sidebar").toggleClass("toggled");
            $("#sidebar").toggleClass("toggled");
          });
        $("#show-sidebar").click(function (e) {
          e.preventDefault();
          $("#show-sidebar").toggleClass("toggled");
          $("#sidebar").toggleClass("toggled");
        });
      })
      this.getOtherLinks();
      this.getImportantLinks();
  }

  getOtherLinks() {
    let id = 0;
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/' + id, {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.status == 'Active' && x.title == "Other Links");
      this.title = this.MenuList[0].title;
      this.subMenuList = data.menuMasterDTO.filter(x => x.status == 'Active' && x.parentId == this.MenuList[0].id);
      for (let i = 0; i < this.subMenuList.length; i++) {
        var id = this.subMenuList[i].id;
        this.getsublinks(id);
      }
    });
  }

  getsublinks(id: any) {
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/' + id, {
    }).subscribe((data: any) => {
      this.otherLinks.push(data.menuMasterDTO[0]);

    });
  }

  getImportantLinks() {
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByMenuName/Important Links', {
    }).subscribe((data: any) => {
      this.importantLinks = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
    });
  }
}
