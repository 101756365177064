<section class="pt-5 pb-5" *ngIf="albumSpinner">
  <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots"
               [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
</section>
<!--<section class="pt-5 pb-5 " id="photogallery" *ngIf="!multipleEntry">
  <div class="container-fluid">
    <div class="container">
      <h2 class="title" *ngIf="title">{{title}}</h2>
      <div class="row mt-3 mb-5" *ngFor="let album of albumList|orderBy :'albumList.listing':'desc'">
        <div class="col-lg-3 text-center mb-4 hovereffect">
          <div (click)="getAlbumImages(album)">
            <img class="img-fluid" *ngIf="!album.coverimage" src="../../../assets/Images/noimage.png" />
            <img class="img-fluid" *ngIf="album.coverimage" [src]="imgUrl + album.coverimage" alt="">
            <div class="w-100 p-2 text-left bg-dark corner-cut corner-danger">
              <span class="new-cus" *ngIf="currentDate==album.createdOn">New</span>
              <label>{{album.albumname}}</label>
              <label>{{album.title}}</label>
              <h6>{{album.images.length}} Images</h6>
            </div>
          </div>
        </div>
        <div class=" col-lg-9 filter mb-3" *ngIf="albumimage">
          <div class="row">
            <div class="gallery_product mb-4 col-lg-4" *ngFor="let albumimg of album.images;let id=index;">
              <a data-toggle="modal" data-target="#images" (click)="showimage(id)" class="text-center w-100 hovereffect ">
                <div *ngIf="album.coverimage" (click)="getAlbumImages(album)">
                  <img class="img-fluid" style="height:10.6rem;" *ngIf="albumimg" [src]="imgUrl + albumimg.filepath" alt="">
                  <div class="w-100 p-2 text-left bg-dark corner-cut corner-danger" *ngIf="albumimg">
                    <span class="new-cus" *ngIf="currentDate==album.createdOn">New</span>
                    <label>{{albumimg.caption }} dfvdfv</label>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>-->
      <!--<div class="row mt-3 mb-5">
        <div class="col-lg-3 text-center mb-4 hovereffect " *ngFor="let image of withoutAlbumImages;let i=index;">
          <div data-toggle="modal" data-target="#randomImage">
            <img class="img-fluid images" [src]="imgUrl + image.filepath" alt="First slide" data-target="#carouselExample1" [attr.data-slide-to]="i">
            <div class="w-100 p-2 text-left bg-dark corner-cut corner-danger">
              <label class="font-weight-bold">{{image.caption}}dfghjk</label>
            </div>
          </div>
        </div>
      </div>-->
    <!--</div>
  </div>
</section>-->

<section class="pt-5 pb-5" *ngIf="albumImageSpinner">
  <ngx-spinner bdColor="rgba(0,0,0,0)" size="medium" color="#0d0a0a" type="ball-elastic-dots"
               [fullScreen]="false"><p style="color:black">loading</p></ngx-spinner>
</section>
<section class="pt-5 pb-5 test" id="photogallery" >
  <div class="container-fluid">
    <div class="container">
      <h2 class="title" *ngIf="!album">{{title}}</h2>
      <div class="row" *ngIf="!album">
        <div class="col-lg-3 text-center mb-4 hovereffect " *ngFor="let album of albumList">

          <div (click)="getAlbumImages(album)">
            <img class="img-fluid" *ngIf="!album.coverimage" src="../../../assets/Images/noimage.png" />
            <img class="img-fluid images" *ngIf="album.coverimage" [src]="imgUrl + album.coverimage" alt="">
            <div class="w-100 p-2 text-left bg-dark corner-cut corner-danger">
              <span class="new-cus" *ngIf="currentDate==album.createdOn">New</span>
              <label class="font-weight-bold">{{album.albumname}}</label>
              <h6>{{album.images.length}} Images</h6>
            </div>
          </div>
        </div>
        <div  class="col-lg-3 text-center mb-4 hovereffect " *ngFor="let image of withoutAlbumImages;let i=index;">
          <div  data-toggle="modal" data-target="#randomImage">
            <img class="img-fluid images" [src]="imgUrl + image.filepath" alt="First slide" data-target="#carouselExample1" [attr.data-slide-to]="i">
            <div class="w-100 p-2 text-left bg-dark corner-cut corner-danger">
              <label class="font-weight-bold">{{image.caption}}</label>
            </div>
          </div>
        </div>
      </div>
      <h2 class="title" *ngIf="albumimage">
        {{albumtitle}}
        <a class="btn back font-weight-bold pull-right" (click)="back()">
          <i class="fa fa-long-arrow-left arrow1 mr-3" aria-hidden="true"></i>Back
        </a>
      </h2>
      <div class="row" id="gallery" data-toggle="modal" data-target="#exampleModal" *ngIf="albumimage">
        <div class="col-12 col-sm-6 col-lg-3" *ngFor="let albumimg of albumimages;let i=index;">
          <img class="w-100 images" [src]="imgUrl + albumimg.filepath" alt="First slide" data-target="#carouselExample" [attr.data-slide-to]="i">
          <div class="w-100 text-left text-light p-2">
            <h6 class="font-weight-bold">{{albumimg.caption}}</h6>
          </div>
        </div>
      </div>

    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">
              {{albumtitle}}
            </h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div id="carouselExample" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExample" [attr.data-slide-to]="id" *ngFor="let Carousel of albumimages;let id=index" class="{{id=='0'?'active':''}}"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item {{id=='0'?'active':''}} text-center" *ngFor="let Carousel of albumimages;let id=index">
                  <img class="img-fluid" *ngIf="Carousel" [src]="imgUrl+Carousel.filepath" alt="First slide">
                  <div class="carousel-caption" style="background: rgba(2, 2, 2, 0.7)">
                    {{Carousel.caption}}
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modal for without album images-->
    <div class="modal fade" id="randomImage" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div id="carouselExample1" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExample1" [attr.data-slide-to]="id" *ngFor="let Carousel of withoutAlbumImages;let id=index" class="{{id=='0'?'active':''}}"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item {{id=='0'?'active':''}} text-center" *ngFor="let Carousel of withoutAlbumImages;let id=index">
                  <img class="img-fluid" *ngIf="Carousel" [src]="imgUrl+Carousel.filepath" alt="First slide">
                  <div class="carousel-caption" style="background: rgba(2, 2, 2, 0.7)">
                    {{Carousel.caption}}
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExample1" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExample1" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>

