import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderPipe } from 'ngx-order-pipe';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-contactus',
    templateUrl: './contactus.component.html',
    styleUrls: ['./contactus.component.scss']
})
/** contactus component*/
export class ContactusComponent implements OnInit {
  /** footer ctor */
  MenuList: any;
  subMenuList: any;
  footer: any;
  contact: any;
  title: any;
  otherLinks: any = [];
  importantLinks: any;
  route: any;
    importantLinksSpinner: boolean;
    otherLinksSpinner: boolean;
    contactSpinner: boolean;
  constructor(private appComponent: AppComponent, private http: HttpClient, private orderpipe: OrderPipe, private router: Router) {
    var str = this.router.url;
    this.route = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }
  ngOnInit() {
    this.getContactUs();
    this.getOtherLinks();
    this.getImportantLinks();
  }

  getContactUs() {
    this.contactSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByRoute/contactus', {
    }).subscribe((data: any) => {
      this.contact = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);    
      //this.getOtherLinks();
      this.contactSpinner = false;
    });
  }

  getOtherLinks() {
    this.otherLinksSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/', {
    }).subscribe((data: any) => {
      this.MenuList = data.menuMasterDTO.filter(x => x.status == 'Active' && x.title == "Other Links");
      this.title = this.MenuList[0].title;
      this.otherLinksSpinner = false;
      this.subMenuList = data.menuMasterDTO.filter(x => x.status == 'Active' && x.parentId == this.MenuList[0].id);
      for (let i = 0; i < this.subMenuList.length; i++) {
        var id = this.subMenuList[i].id;
        this.getsublinks(id);
      }
    });
  }

  getsublinks(id: any) {
    this.http.get(this.appComponent.APIUrl + 'MenuMaster/' + id, {
    }).subscribe((data: any) => {
      this.otherLinks.push(data.menuMasterDTO[0]);
      //this.getImportantLinks();
    });
  }

  getImportantLinks() {
    this.importantLinksSpinner = true;
    this.http.get(this.appComponent.APIUrl + 'Post/GetPostByMenuName/Important Links', {
    }).subscribe((data: any) => {
      this.importantLinks = data.postDTO.filter(x => x.status == 'Active' && x.displayonhome == 1);
      this.importantLinksSpinner = false;
    });
  }

}
